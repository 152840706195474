<template>
  <div class="container-title">
    <slot>
      <div class="flex items-center" style="gap: 8px">
        <p class="title">{{ title }}</p>
        <el-tooltip v-if="!hideTooltip" placement="right">
          <div slot="content" :style="`width: ${tooltipWidth}px`">
            <slot name="tooltip">{{ tooltip }}</slot>
          </div>
          <span class="material-icons">help_outline</span>
        </el-tooltip>
      </div>
    </slot>
    <slot name="description">
      <p v-if="description" class="description">{{ description }}</p>
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ContainerTitle',
  props: {
    title: { type: String, default: 'Container Title' },
    description: { type: String, default: '' },
    tooltipWidth: [String, Number],
    tooltip: String,
    hideTooltip: Boolean,
  },
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>
.title {
    font-size: 18px;
    @apply text-primary-100 font-bold leading-[26.06px];
}
.description {
    @apply text-gray-80 text-sm font-medium leading-[17.38px];
}
</style>
