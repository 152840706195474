<template>
  <el-form v-bind="$attrs">
    <el-form-item
      v-for="info in displayData"
      :key="info.label"
      :label="info.label"
    >
      <p>{{ info.value }}</p>
    </el-form-item>
  </el-form>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'InfoList',
  props: { displayData: { type: Array, default: () => [] } },
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>

::v-deep .el-form-item{
  @apply mb-0;
}
</style>
