<template>
  <div class="member-invite-setting">
    <PageTitle title="推薦會員活動設定" @btnClick="$router.push({name: 'MemberInviteEdit'})" />

    <div class="section-wrapper">
      <MemberInviteInfoBlock :configData="configData" />
      <MemberInviteEventBlock :relatedEvent="relatedEvent" />
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'
import MemberInviteInfoBlock from './components/MemberInviteInfoBlock.vue'
import MemberInviteEventBlock from './components/MemberInviteEventBlock.vue'
import { useFetch } from '@/use/fetch'
import { GetMemberInviteConfig, GetMemberInviteRelatedEvent } from '@/api/memberInvite'
import { useShop } from '@/use/shop'
import { get, find } from 'lodash'

export default defineComponent({
  name: 'MemberInviteSetting',
  components: { MemberInviteInfoBlock, MemberInviteEventBlock },
  setup (props) {
    const { simpleFetch } = useFetch()
    const { shopId } = useShop()

    const configData = ref({})
    const relatedEvent = ref([])

    onMounted(async () => {
      simpleFetch(GetMemberInviteConfig, { shopId: shopId.value }, res => {
        configData.value = res
      })
      simpleFetch(GetMemberInviteRelatedEvent, { shopId: shopId.value }, res => {
        relatedEvent.value = res
      })
    })

    return { configData, relatedEvent }
  },
})
</script>

<style lang="postcss" scoped>
.section-wrapper {
  @apply flex flex-col gap-[20px];
}
</style>
