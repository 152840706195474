<template>
  <div class="member-invite-info-block side-highlight-container">
    <section>
      <ContainerTitle title="啟用狀態" hideTooltip />
      <InfoList
        class="status-content"
        label-position="left"
        label-width="280px"
        :displayData="displayEnabledData"
      />
    </section>

    <section class="block-section">
      <ContainerTitle title="活動頁設定" hideTooltip />
      <div>
        <p class="section-label">說明文字設定</p>
        <div class="info-text">{{ configData.description }}</div>
      </div>
    </section>

    <section class="block-section">
      <ContainerTitle title="會員分享 Line 訊息設定" hideTooltip />
      <div>
        <p class="section-label">文字訊息設定</p>
        <div class="info-text">Hi推薦你成為{分店}會員～{{ configData.shareMessage }}</div>
      </div>

      <div>
        <p class="section-label">訊息附圖設定</p>
        <div v-if="configData.ShareImages?.length" class="img-preview-container">
          <img
            v-for="img in configData.ShareImages"
            :key="img.id"
            class="img-preview"
            :src="imgSrc(300, img)"
          >
        </div>
        <p v-else class="info-text">尚無設置附圖</p>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import ContainerTitle from '@/components/Title/ContainerTitle.vue'
import InfoList from '@/components/InfoList.vue'
import { get } from 'lodash'
import { imgSrc } from '@/utils/helper'

export default defineComponent({
  name: 'MemberInviteInfoBlock',
  components: { ContainerTitle, InfoList },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const displayEnabledData = computed(() => {
      const enableActivity = get(props.configData, 'displayReferralActivity')
      // const enableRegister = get(props.configData, 'allowReferralRegister')
      return [
        { label: '啟用「推薦好友」活動', value: enableActivity ? '開啟' : '關閉' },
        // { label: '註冊流程顯示「填寫推薦碼」欄位', value: enableRegister ? '顯示' : '隱藏' },
      ]
    })

    return { displayEnabledData, imgSrc }
  },
})
</script>

<style lang="postcss" scoped>
.info-text {
  @apply text-gray-60 leading-[20.27px] whitespace-pre-wrap text-[14px];
}

.img-preview-container {
  @apply flex gap-[20px];
}
.img-preview {
  @apply w-[90px] h-[90px];
}

.block-section {
  @apply flex flex-col gap-[20px];
}

.section-label {
  @apply mb-[8px] font-medium text-normal text-base text-[#606266];
}

::v-deep .status-content .el-form-item__label,::v-deep .status-content .el-form-item__content {
  @apply text-[16px]
}
</style>
